<template>
  <div>
    <div class="row no-gutters">
       <content-filter
        class="col-8"
        :id="listId"
        @filter="filtering"
        :type="'miniFilter'"
        store-name="Filter"
        :org-filtering="true"
        :limit-to-organization-ids="[organizationId]"
        :org-filter-local="true"
      />
      <Button class="col mr-2 ml-2" @click="$emit('create')">{{ $t('New') }} <icon type="plus" /></Button>
    </div>

    <pagination
        slot="mainContentButtons"
        :limit="paginationLimit"
        :total="$store.getters.getFilterListOptions($route.params.id, 'pagination_items')"
        id="exportprofileList"
        @setActive="loadPaginatedData"
    />
    <div class="mb-3 mt-2 export-profile-teaser" :key="id" v-for="(item, id) in $store.getters.getFiltersByListName(listId)">
      <div class="row">
          <Button class="icon-button profile-edit-button" @click="$emit('edit', item.id)"><icon type="cog" /></Button>
          <div class="col-1 mt-1 text-right"><icon type="file-export" size="1.2" /></div>
          <div class="col"><p class="larger"><b>{{ item.name }}</b></p>
          <p class="lighter smaller">{{ $t('Created') }}: {{ dateFromISO(item.createdAt) }}</p>
          <div v-if="item.description" class="mb-1">{{ item.description }}<br ></div>
          <tag class="mt-2 mb-2 mr-1" icon-left="users" :is-removable="false" :key="team.id" v-for="team in item.teams" :tag="team" :organization-id="team.organizationId" />
          <!--<div class="darker p-2">
            {{ item.content && JSON.parse(item.content) ? JSON.parse(item.content).filter : ''}}
          </div>-->
          <!--<Button class="mt-1 mr-1" @click="deleteFilter(item.id)">{{ $t('delete') }} <icon type="trash-alt" /></Button>-->
          <div class="w-100" />
            <state-display class="float-right" :state="item.state" />
          <Button class="mt-3 move-to-right-animation highlighted" @click="$emit('createInstance', item)">{{ $t('createNewSFXFromExportProfile') }} <icon type="angle-right" /></Button>
        </div>
        </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/forms/Button";
import ContentFilter from "@/components/widgets/filter/ContentFilter";
import DateMixin from "@/components/mixins/DateMixin.js";
import Icon from "@/components/Icon";
import Pagination from "@/components/Pagination";
import Tag from "@/components/Tag";
import StateDisplay from "@/components/StateDisplay";

export default {
  name: "ExportProfileList",
  components: {
    Button,
    ContentFilter,
    Icon,
    Pagination,
    Tag,
    StateDisplay
  },
  mixins: [DateMixin],
  props: {
    organizationId: {type: String, required: true},
    listId: {type: String, required: true}
  },
  data() {
    return {
      paginationLimit: 4,
      offset: 0,
      list: {},
      filter: {},
      queryRelevantComponents: 2, //pagination and filter
      queryRelevantInits: 0,
      teams: []
    }
  },
  methods: {
    loadFilters(initial = false) {
      let args =  {
        filter: 'type eq filter',
        listName: this.listId,
      }
      if(this.filter) {
        if (this.filter.add) { args.add = this.filter.add}
        if (this.filter.remove) { args.remove = this.filter.remove }
      }
      const paginationAddOn = {
        limit: {
          default: this.paginationLimit
        },
        offset: {
          default: this.offset,
        },
        paging: {
          default: true
        },
        include: {
          default: 'teams'
        }
      }
      args.add = this.filter.add ? {...paginationAddOn, ...this.filter.add } : paginationAddOn;
      if(!initial || this.queryRelevantInits >= this.queryRelevantComponents) {
        this.$store.dispatch('loadFilters', args).then(data => {
          this.list = data;
        });
      } else {
        this.$store.dispatch('registerFilterQueryParams', {listName: this.listId, params: args});
      }
    },
    filtering(val, initial) {
      this.queryRelevantInits++;
      this.filter = val;
      this.loadFilters(initial);
    },
    loadPaginatedData(offset, initial) {
      this.queryRelevantInits++;
      this.offset = offset;
      this.loadFilters(initial);
    }
  }
}
</script>

<style lang="scss">
  .export-profile-teaser {
    position:relative;
    background-color: #605E5B;
    padding:15px;
    p {
      z-index: 0;
      position:relative;
    }
  }
  .profile-edit-button {
    z-index:1;
    position:absolute;
    top:15px;
    right: 15px;
    display:inline-block;
  }
</style>